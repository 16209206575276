@use '../../utils' as *;

.header {
  position: sticky;
  top: 0;
  padding-block: em(8);
  background: hsl(5, 5%, 5%, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 999;
  @media screen and (max-width: em(1000)) {
    background: rgba(13, 12, 12);
  }
  &_navbar {
    width: 90%;
    position: relative;
    margin-inline: auto;
    @media screen and (max-width: em(1000)) {
      width: 100%;
    }

    @include flex-jBetween-aCenter();
    .navbar-right {
      display: flex;
      gap: rem(70);
      &.show {
        .connect-btn {
          display: flex;
        }
        .header_navbar_icons {
          display: flex;
        }
        .header_navbar_navbtnslist {
          @media screen and (max-width: em(1000)) {
            display: flex;
            flex-direction: column;
            padding-top: rem(30);
          }
        }
        .header_navbar_address {
          @media screen and (max-width: em(1000)) {
            display: flex;
            padding-bottom: rem(50);
          }
        }
      }
      .connect-btn {
        @media screen and (max-width: em(1000)) {
          display: none;
          padding-bottom: rem(50);
        }
      }
      @media screen and (max-width: em(1000)) {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 42px;
        background-color: black;
      }
      @media screen and (max-width: em(1250)) {
        gap: rem(20);
      }
    }
    .toggle {
      display: none;
      @media screen and (max-width: em(1000)) {
        display: block;
        width: rem(30);
        display: flex;
        justify-content: flex-end;
        margin-right: 3rem;
      }
      @media screen and (max-width: em(500)) {
        margin-right: 1.5rem;
        width: rem(50);
      }
    }
    &_logo {
      display: grid;
      place-content: center;
      width: rem(80);
      @media screen and (max-width: em(1000)) {
        margin-left: 3rem;
      }
      @media screen and (max-width: em(500)) {
        margin-left: 1.5rem;
      }
    }

    &_searchbox {
      background-color: $clr-black-65;
      padding-inline: em(16);
      border-radius: em(20);
      position: relative;
      @include flex-jCenter-aCenter();

      @media screen and (max-width: em(500)) {
        padding-block: 0.2em;
      }
      @media screen and (max-width: em(360)) {
        gap: 0.8rem;
      }
      &-icon {
        display: grid;
        place-content: center;
        font-size: rem(20);
      }

      &-input {
        color: $clr-white;
        padding-block: em(5);
        width: rem(300);
        font-size: rem(15);

        @media screen and (max-width: em(1120)) {
          min-width: 0;
        }

        &::placeholder {
          color: $clr-white-50;
          font-size: rem(14);
          @media screen and (max-width: em(450)) {
            font-size: rem(12);
          }
        }

        &:hover,
        &:focus {
          outline: none;
        }
      }
    }

    &_navbtnslist {
      @include flex-jCenter-aCenter($gap: rem(24));
      white-space: nowrap;
      li {
        font-size: rem(15.5);
      }
      @media screen and (max-width: em(1000)) {
        display: none;
      }
    }

    &_icons {
      @include flex-jCenter-aCenter();
      font-size: rem(18);
      @media screen and (max-width: em(1000)) {
        display: none;
      }
      & > * {
        display: grid;
        place-content: center;
      }
      &-notification {
        animation: none;
        &:hover {
          animation: notification 350ms linear;
          transform-origin: top center;
        }
      }
      &-settings {
        transform: rotate(0);
        transition: transform 250ms linear;

        &:hover {
          transform: rotate(20deg);
          transition: transform 250ms linear;
        }
      }
    }

    &_address {
      position: relative;
      @include flex-jCenter-aCenter($gap: 0);
      @media screen and (max-width: em(1000)) {
        display: none;
      }
      &-avatar {
        display: grid;
        place-content: center;
        aspect-ratio: 1;
        width: rem(40);
        border-radius: 50%;
        outline: 2px solid $clr-black;
        overflow: hidden;
        right: rem(0);
        position: absolute;

        img {
          aspect-ratio: 1;
          width: rem(50);
        }
      }

      &-address {
        font-size: rem(13);
        letter-spacing: 0.02em;
        padding: em(3) em(10);
        background-color: $clr-black;
        border-radius: 5px;
        width: calc(15ch + rem(20));
      }
    }
  }
}
@keyframes notification {
  from,
  50%,
  to {
    transform: rotate(10deg);
  }
  25%,
  75% {
    transform: rotate(-10deg);
  }
}
