@use '../../utils' as *;

.home {
  padding-top: rem(20);
  padding-bottom: rem(40);
  @include flex-jCenter-aCenter($dir: column, $gap: rem(30));
  .registration-link {
    width: 100%;
  }
  &_hero {
    @include flex-jCenter-aCenter();
    position: relative;
    width: 100%;
    @media screen and (max-width: em(1150)) {
      flex-direction: column;
    }
    video {
      width: 100%;
      object-fit: fill;
    }

    &_content {
      position: absolute;
      @include flex-jCenter-aCenter($gap: 0, $dir: column);
      bottom: 2vw;

      @media screen and (max-width: em(1150)) {
        position: relative;
        margin-top: rem(25);
      }
      p {
        font-size: rem(19);
        line-height: 1.3;
        text-align: center;
        @media screen and (max-width: em(1150)) {
          font-size: rem(15);
        }
        @media screen and (max-width: em(500)) {
          font-size: rem(12);
        }
      }

      .register-btn {
        background-color: white;
        border-radius: 5px;
        padding-inline: rem(42);
        padding-block: em(8);
        margin-top: rem(15);
        font-size: rem(20);
        font-weight: $fw-semibold;
        border: none;
        outline: none;
        @media screen and (max-width: em(500)) {
          padding-inline: 1rem;
          padding-block: 0.3em;
          font-size: 1rem;
        }
      }
    }
  }

  &_container {
    width: 90%;
    margin-inline: auto;
    @media screen and (max-width: em(1000)) {
      width: 100%;
      padding-inline: 3rem;
    }
    @media screen and (max-width: em(500)) {
      padding-inline: 1.5rem;
    }
    &_store {
      font-size: rem(20);
      display: flex;
      align-items: center;
      gap: 5px;
      @media screen and (max-width: em(500)) {
        font-size: rem(15);
      }
      & span {
        display: flex;
        align-items: center;
        font-size: rem(14);
        font-weight: 100;
        color: #9d9d9d;
      }
    }

    &_sections {
      font-size: 14px;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;

      &_viewall {
        cursor: pointer;
        white-space: nowrap;
        @media screen and (max-width: em(550)) {
          padding-left: 50px;
          p {
            font-size: 0.8rem;
          }
        }
      }

      &_points {
        display: flex;
        gap: 30px;
        @media screen and (max-width: em(550)) {
          width: fit-content;
          overflow-x: scroll;
          overflow-y: hidden;
          gap: 20px;
          -ms-overflow-style: none;
          scrollbar-width: 0;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        & li {
          display: inline-grid;
          place-content: center;
          position: relative;
          cursor: pointer;
          white-space: nowrap;
          @media screen and (max-width: em(500)) {
            font-size: 0.8rem;
          }

          &::after {
            content: '';
            // position: absolute;
            height: 1px;
            left: 50%;
            width: 0;
            margin-inline: auto;
            bottom: -2px;
            background-color: white;
            transition: all 250ms linear;
          }

          &:hover::after {
            width: 100%;
            left: 0;
            transition: all 250ms linear;
          }

          &.active::after {
            width: 100%;
            left: 0;
          }
        }
      }
    }

    &_images {
      gap: rem(25);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: rem(30);
      grid-template-rows: repeat(2, rem(200));
      @media screen and (max-width: em(700)) {
        display: flex;
        flex-direction: column;
      }
      &_img {
        border-radius: rem(15);
        overflow: hidden;
        background-color: $clr-black-50;
        position: relative;

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          position: absolute;
          object-position: top;
        }

        &:nth-of-type(1) {
          & img {
            object-position: unset;
          }
          top: 0;
        }
        @media screen and (max-width: em(700)) {
          position: relative;
          height: rem(300);
        }
        @media screen and (max-width: em(500)) {
          position: relative;
          height: rem(200);
        }
      }

      &:has(div:nth-child(1)) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, rem(250));

        div {
          grid-column: span 1;
          grid-row: 1/-1;
        }
      }

      &:has(div:nth-child(2)) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: rem(375);

        div {
          grid-column: span 1;
          grid-row: 1/-1;
        }
      }

      &:has(div:nth-child(3)) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, rem(200));

        div {
          grid-column: 3/4;
          grid-row: span 1;

          &:first-of-type {
            grid-column: 1/3;
            grid-row: 1/-1;
          }
        }
      }
    }
  }
}
