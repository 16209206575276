@use '../../utils' as *;

.modal {
  position: fixed;
  inset: 0;
  background-color: hsl(0, 0%, 0%, 0.5);
  @include flex-jCenter-aCenter();
  z-index: 10;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 99999;

  &_modalContainer {
    background: hsla(0, 0%, 0%, 0.5);
    backdrop-filter: blur(20px);
    border: 1px solid rgb(136, 136, 136, 0.07);
    padding-block: em(30);
    padding-inline: em(20);
    width: min(90%, rem(600));
    @include flex-jCenter-aCenter($dir: column, $gap: rem(50));
    border-radius: 6px;
    position: relative;
    animation: popup 250ms linear;
    @media screen and (max-width: em(600)) {
      width: rem(300);
      gap: rem(25);
      padding-block: em(30);
      padding-inline: em(10);
    }
    &_closeBtn {
      position: absolute;
      right: rem(10);
      top: 0;
      font-size: rem(28);
      color: rgb(136, 136, 136, 0.8);
      cursor: pointer;
      @media screen and (max-width: em(600)) {
        font-size: rem(20);
      }

      &:hover {
        color: $clr-white;
        transition: color 100ms linear;
      }
    }

    &_modalHeading {
      font-size: rem(20);
      font-weight: $fw-medium;
      letter-spacing: 0.03em;
      text-align: center;
      @media screen and (max-width: em(600)) {
        font-size: rem(15);
      }
    }

    &_ctaBtnsContainer {
      @include flex-jCenter-aCenter($gap: rem(15), $dir: column);
      width: 100%;

      &_ctaBtns {
        @include flex-jCenter-aCenter($gap: rem(10));
        background-color: $clr-black;
        color: $clr-white;
        width: min(90%, rem(340));
        height: rem(42.5);
        font-size: rem(14);
        border-radius: 8px;
        border: 1px solid rgb(136, 136, 136, 0.135);
        transition: border 200ms linear;

        &:hover {
          border: 1px solid rgb(136, 136, 136, 0.25);
          transition: border 200ms linear;
        }

        &_btnLogo {
          display: grid;
          place-content: center;
          width: rem(35);
        }
      }

      &_orText {
        text-transform: uppercase;
        @include flex-jCenter-aCenter($gap: rem(6));

        &::before,
        &::after {
          content: '';
          display: block;
          border: 1px solid $clr-white;
          width: rem(20);
        }
      }
    }
  }
}

.googleLogo {
  width: rem(25);
}

.alertModal {
  position: absolute;
  background-color: transparent;

  .modal_modalContainer {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(15px);
    @include flex-jCenter-aCenter($dir: column, $gap: rem(30));
    height: rem(260);
    width: rem(480);
    @media screen and (max-width: em(600)) {
      height: rem(200);
      width: rem(380);
    }
    @media screen and (max-width: em(400)) {
      height: rem(150);
      width: rem(280);
    }
    .alertMsg {
      font-size: rem(20);
      text-transform: capitalize;
      @media screen and (max-width: em(600)) {
        font-size: rem(15);
      }
    }

    .btns-container {
      @include flex-jCenter-aCenter($gap: rem(60));

      button {
        padding-inline: rem(20);
        padding-block: rem(5);
        border-radius: 4px;
        background-color: $clr-black;
        border: 1px solid rgb(136, 136, 136, 0.18);
        color: $clr-white;
        width: rem(75);
        font-weight: $fw-medium;
        transition: background-color 100ms linear, border-color 100ms linear, color 100ms linear;
        @media screen and (max-width: em(600)) {
          padding-inline: rem(10);
          font-size: rem(12);
        }

        &:hover {
          background-color: $clr-white;
          color: $clr-black;
          border-color: $clr-white;
          transition: background-color 100ms linear, border-color 100ms linear, color 100ms linear;
        }
      }
    }
  }
}

@keyframes popup {
  from {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
