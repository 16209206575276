@use '../../utils' as *;

.search-result-container {
  position: absolute;
  top: calc(100% + rem(5));
  display: grid;
  width: 100%;
  background-color: #191919;
  max-height: rem(350);
  overflow-y: auto;
  border-radius: 5px;
  -ms-overflow-style: none;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .games-list {
    display: grid;
    border: 1px solid hsl(0, 0%, 100%, 0.05);
    border-radius: 5px;
    overflow: hidden;

    .games-list-items {
      border-bottom: 1px solid hsl(0, 0%, 100%, 0.05);
      padding-inline: 1rem;
      padding-block: 0.75rem;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      cursor: pointer;

      &:first-of-type {
        padding-block-start: 1rem;
      }

      &:last-of-type {
        padding-block-end: 1rem;
        border-bottom: 0;
      }

      &:hover {
        background-color: hsl(0, 0%, 100%, 0.05);
      }

      .game-thumbnail {
        display: grid;

        img {
          aspect-ratio: 1;
          width: rem(40);
          border-radius: 3px;
        }
      }

      .game-information {
        flex: 1;
        display: grid;

        .game-name {
          font-size: rem(14);
        }

        .game-description {
          font-size: rem(11.5);
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .no-game-item {
      padding-block-start: 0.75em !important;
      padding-block-end: 0.75em !important;

      .no-data-name {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-weight: 500;

        .search-icon {
          font-size: rem(20);
        }
      }

      .no-data-msg {
        font-size: rem(12.5);
        margin-top: rem(5);
        font-weight: 300;
        letter-spacing: 0.04em;
        color: hsl(0, 0%, 100%, 0.5);
      }
    }

    .skeleton {
      .game-thumbnail {
        aspect-ratio: 1;
        width: rem(40);
        border-radius: 3px;
        background: hsl(0, 0%, 100%, 0.15);
        animation: skeleton-loading 600ms linear infinite alternate;
      }

      .game-information {
        gap: 0.25rem;

        .game-name {
          width: 75%;
          height: calc(1.35 * rem(14));
          border-radius: 3px;
          background: hsl(0, 0%, 100%, 0.15);
          animation: skeleton-loading 600ms linear infinite alternate;
        }

        .game-description {
          height: calc(1.35 * rem(11.5));
          border-radius: 3px;
          background: hsl(0, 0%, 100%, 0.15);
          animation: skeleton-loading 600ms linear infinite alternate;
        }
      }
    }
  }
}

@keyframes skeleton-loading {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 0.75;
  }
}
