@use 'functions' as fn;
@use 'variables' as var;

@mixin media($type, $breakpoint) {
  @if $type == min {
    @media screen and (min-width: map-get(var.$breakpoints-min, $breakpoint)) {
      @content;
    }
  } @else {
    @media screen and (max-width: map-get(var.$breakpoints-max, $breakpoint)) {
      @content;
    }
  }
}

/* ===== Flexboxes ===== */

// Justify: Start =====
// ----------------------------------------------------------------
@mixin flex-jStart-aStart($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-start;
  align-items: flex-start;
  gap: $gap;
}

@mixin flex-jStart-aEnd($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-start;
  align-items: flex-end;
  gap: $gap;
}

@mixin flex-jStart-aCenter($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-start;
  align-items: center;
  gap: $gap;
}

@mixin flex-jStart-aStretch($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-start;
  align-items: stretch;
  gap: $gap;
}

// Justify: End =====
// ----------------------------------------------------------------
@mixin flex-jEnd-aStart($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-end;
  align-items: flex-start;
  gap: $gap;
}

@mixin flex-jEnd-aEnd($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-end;
  align-items: flex-end;
  gap: $gap;
}

@mixin flex-jEnd-aCenter($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-end;
  align-items: center;
  gap: $gap;
}

@mixin flex-jEnd-aStretch($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: flex-end;
  align-items: stretch;
  gap: $gap;
}

// Justify: Center =====
// ----------------------------------------------------------------
@mixin flex-jCenter-aStart($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: center;
  align-items: flex-start;
  gap: $gap;
}

@mixin flex-jCenter-aEnd($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: center;
  align-items: flex-end;
  gap: $gap;
}

@mixin flex-jCenter-aCenter($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: center;
  align-items: center;
  gap: $gap;
}

@mixin flex-jCenter-aStretch($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: center;
  align-items: stretch;
  gap: $gap;
}

// Justify: Space Between =====
// ----------------------------------------------------------------
@mixin flex-jBetween-aStart($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: space-between;
  align-items: flex-start;
  gap: $gap;
}

@mixin flex-jBetween-aEnd($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: space-between;
  align-items: flex-end;
  gap: $gap;
}

@mixin flex-jBetween-aCenter($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: space-between;
  align-items: center;
  gap: $gap;
}

@mixin flex-jBetween-aStretch($dir: row, $gap: 1rem) {
  display: flex;
  flex-direction: $dir;
  justify-content: space-between;
  align-items: stretch;
  gap: $gap;
}
