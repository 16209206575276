@use 'functions' as fn;
@use 'variables' as var;
@use 'mixins' as mx;

html {
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto !important;
  }

  &:focus-within {
    @extend html;
  }

  @include mx.media(min, '4xl') {
    font-size: 125%;
  }

  @include mx.media(min, '5xl') {
    font-size: 150%;
  }

  @include mx.media(min, '7xl') {
    font-size: 180%;
  }

  @include mx.media('max', 'sm') {
    font-size: 95%;
  }

  @include mx.media('max', 'mini') {
    font-size: 90%;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: inherit;
  -webkit-tap-highlight-color: transparent;

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  &::before,
  &::after {
    @extend *;
  }
}

body {
  backdrop-filter: blur(10px);
  display: block;
  line-height: 1.5;
  width: 100%;
  min-height: 100vh;
  font-size: fn.rem(16);
  font-weight: var.$fw-regular;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
  position: relative;
  font-family: 'Poppins', sans-serif;
  background-color: var.$clr-bg-primary;
  color: var.$clr-white;
}

h1,
h2,
h3 {
  line-height: 1.1;
  margin-block: 0;
}

p {
  margin-block: 0;
  color: inherit;
}

a {
  color: unset;
  text-decoration: none;
  cursor: pointer;

  &:visited,
  &:active {
    @extend a;
  }

  &:not([class]) {
    text-decoration-skip-ink: auto;
  }
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
  vertical-align: middle;
}

input,
select {
  border: none;
  background: transparent;
  font: inherit;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type='search'] {
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  &[type='radio'],
  &[type='checkbox'] {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: transparent !important;
    }
  }
}

textarea {
  resize: none;
  border: none;
  font: inherit;
}

button {
  cursor: pointer;
  border: none;
  display: grid;
  place-content: center;
  text-align: center;
  font: inherit;

  &:disabled {
    cursor: not-allowed;
  }
}

nav {
  z-index: 999999;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 7px;
}
