@use '../../utils' as *;

.profile {
  padding-block: em(50);

  &_heading {
    text-align: center;
    font-size: rem(20);
    font-weight: $fw-medium;
    margin-bottom: rem(30);
    width: max-content;
    margin-inline: auto;
    padding-inline: 1px;
    color: $clr-text-dull;
  }

  &_container {
    width: 90%;
    margin-inline: auto;
    padding-block: rem(24);
    padding-inline: rem(30);
    border-top: 1px solid rgb(136, 136, 136, 0.25);
    @include flex-jCenter-aCenter($dir: column, $gap: rem(40));
    @media screen and (max-width: em(1000)) {
      width: 100%;
      padding-inline: 3rem;
    }
    @media screen and (max-width: em(500)) {
      padding-inline: 1.5rem;
    }
    &_profileImage {
      color: $clr-text-dull;
      display: grid;
      place-content: center;
      gap: rem(6);
      text-align: center;
      position: relative;

      & img {
        padding: rem(4);
        aspect-ratio: 1;
        width: rem(200);
        border: 1px solid rgb(136, 136, 136, 0.35);
        border-radius: 50%;
        overflow: hidden;
        object-fit: contain;
        z-index: 0;
        background-color: $clr-black-50;
      }

      &_editBtn {
        position: absolute;
        top: 75%;
        transform: translateX(-25%);
        z-index: 2;
        @include flex-jCenter-aCenter($gap: rem(5));
        border: 1px solid rgb(136, 136, 136, 0.35);
        background-color: hsl(0, 0%, 0%, 0.8);
        padding-inline: rem(10);
        padding-block: rem(2);
        border-radius: 5px;
        font-size: rem(13);
        cursor: pointer;
        transition: color 150ms linear;

        & .editIcon {
          font-size: rem(14);
        }

        &:hover {
          color: $clr-white;
          transition: color 150ms linear;
        }
      }
    }

    &_profileDetails {
      width: min(90%, rem(840));
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: rem(15);
      column-gap: rem(30);
      @media screen and (max-width: em(700)) {
        display: flex;
        flex-direction: column;
      }

      &_formControllGroup {
        @include flex-jCenter-aStart($gap: rem(3), $dir: column);
        grid-column: span 1;

        & label {
          color: $clr-text-dull;
          font-size: rem(15);
          padding-inline: rem(5);
        }

        & input,
        & textarea {
          color: $clr-white;
          width: 100%;
          padding-inline: rem(12);
          padding-block: rem(5);
          border: 1px solid rgb(136, 136, 136, 0.135);
          font-weight: $fw-light;
          font-size: rem(15);
          background-color: $clr-black-35;
          border-radius: rem(6);
          transition: border 200ms linear;

          &:read-only {
            color: $clr-white-75;
          }

          &:hover {
            border: 1px solid rgb(136, 136, 136, 0.25);
            transition: border 200ms linear;
          }

          &:focus {
            outline: none;
            border: 1px solid rgb(136, 136, 136, 0.35);
            transition: border 200ms linear;
          }
        }

        &:has(textarea) {
          grid-column: span 2;
        }
      }

      &_formControllBtns {
        margin-top: rem(25);
        grid-column: 1/-1;
        @include flex-jEnd-aCenter();
        button {
          height: rem(34);
        }
        .save-profile {
          button {
            width: 13ch;
          }
          .spin {
            display: grid;
            place-items: center;
            position: relative;
            aspect-ratio: 1;
            border: 50%;
            @keyframes spinner {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
            .loading-spinner {
              width: 23px;
              height: 23px;
              border: 3px solid #383636;
              border-top: 3px solid #f3f3f3;
              border-radius: 50%;
              animation: spinner 1.5s linear infinite;
            }
          }
        }
      }
    }

    &_editBtn {
      margin-top: rem(-40);
    }
  }
}
