@use '../../utils' as *;

.upload {
  @include flex-jCenter-aStretch($gap: rem(40));
  width: 90%;
  margin-inline: auto;
  @media screen and (max-width: em(1000)) {
    width: 100%;
    padding-inline: 3rem;
  }
  @media screen and (max-width: em(500)) {
    padding-inline: 1.5rem;
  }
  padding-block: em(40);
  @media screen and (max-width: em(900)) {
    flex-direction: column;
  }

  &_icons {
    display: grid;
    place-content: center;
    font-size: rem(50);
    color: $clr-text-dull;
  }

  &_leftContainer {
    flex: 1;
    @include flex-jCenter-aStart($dir: column, $gap: rem(25));
    @media screen and (max-width: em(900)) {
      align-items: center;
    }
    &_pageHeading {
      font-size: rem(48);
      font-weight: $fw-medium;
      @media screen and (max-width: em(1150)) {
        font-size: rem(35);
        text-align: center;
      }
      @media screen and (max-width: em(500)) {
        font-size: rem(25);
      }
    }

    &_pageMsg {
      font-size: rem(22);
      color: $clr-text-dull;
      @media screen and (max-width: em(500)) {
        font-size: rem(15);
      }
    }

    &_dropzone {
      flex: 1;
      max-height: rem(200);
      width: 100%;
      max-width: rem(480);

      .upload_icons {
        position: relative;
        z-index: -1;
        .closeBtn {
          padding: rem(5);
          display: flex;
          aspect-ratio: 1;
          width: rem(20);
          position: absolute;
          right: rem(4);
          top: rem(1);
          margin: rem(5);
          background: transparent;
          transform: translateX(100%) translateY(-75%);
        }
      }

      @include flex-jCenter-aCenter($dir: column, $gap: rem(5));
      background: $gradient-uploadpage;
      backdrop-filter: blur(10px);
      position: relative;

      @media screen and (max-width: em(900)) {
        flex: auto;
        height: rem(250);
      }
      &_clickEventController {
        position: absolute;
        inset: 0;
        background-color: transparent;
      }

      &_uploadDoneIcon {
        color: $clr-white;
        animation: zoom-in-out 1.3s linear infinite;
      }

      &_uploadedFileInfo {
        @include flex-jCenter-aCenter($gap: rem(10));
        width: 90%;
        margin-top: rem(8);

        &_fileName {
          width: 25ch;
          padding: em(6) em(16);
          background-color: $clr-black;
          border-radius: 5px;
          @include flex-jStart-aCenter($gap: rem(5));
          font-size: rem(14);
          color: $clr-text-dull;

          & .zip-folder-icon {
            font-size: rem(25);
            color: $clr-white;
          }
        }

        &_infoMsg {
          width: max-content;
        }
      }

      &_uploadingIcon {
        font-size: rem(40);
        margin-top: auto;
        animation: oscillate 500ms linear infinite alternate;
      }

      &_uploadingMsg {
        font-size: rem(14);

        &:last-of-type {
          font-size: rem(12);
          text-align: center;
          padding-inline: rem(16);
          line-height: 1.4;
          margin-top: rem(8);
        }
      }

      &_uploader {
        width: 95%;
        height: rem(5);
        margin-top: auto;
        margin-bottom: rem(10);
        border-radius: rem(5);
        background-color: $clr-black;
        margin-inline: auto;
        position: relative;
        overflow: hidden;

        & div {
          position: absolute;
          inset: 0;
          background-color: white;
          width: 10%;
          border-radius: rem(5);
          transition: width 200ms linear;
          animation: uploading 1.5s ease-in-out infinite;
        }
      }

      &_dragDropMsg {
        font-size: rem(15);
      }
    }

    &_uploadBtns {
      margin-top: rem(10);
      width: 100%;
      max-width: rem(480);
      @include flex-jBetween-aCenter($gap: rem(12));
      flex-wrap: wrap;

      @media screen and (max-width: em(900)) {
        justify-content: center;
      }
    }
  }

  &_rightContainer {
    flex: 1;
    @include flex-jStart-aStart($dir: column, $gap: rem(16));
    background: $gradient-uploadpage;
    backdrop-filter: blur(10px);
    padding-inline: em(15);
    padding-block: em(15);

    &_containerHeading {
      font-size: rem(18);
    }

    &_dropzone {
      width: 90%;
      max-width: rem(320);
      @include flex-jCenter-aCenter($dir: column, $gap: rem(5));
      margin-inline: auto;
      background-color: $clr-black-50;
      position: relative;
      height: rem(200);
      .closeBtn {
        padding: 5px;
        border-radius: 50%;
        display: flex;
        width: rem(20);
        aspect-ratio: 1;
        position: absolute;
        right: 0;
        top: 0;
        margin: rem(5) rem(8);
        background: #2e2d2e;
      }

      &:has(.upload_rightContainer_dropzone_imgPreview) {
        width: max-content;
        height: max-content;
        background-color: transparent;
        border: 1px solid transparent;
      }

      &_clickEventController {
        position: absolute;
        inset: 0;
        background-color: transparent;
      }

      &_imgPreview {
        @include flex-jCenter-aCenter();
        width: max-content;
        max-width: rem(280);

        & img {
          outline: 1px solid rgb(136, 136, 136, 0.25);
        }
      }

      &_dragDropIcon {
        font-size: rem(40);
      }

      &_dragDropMsg {
        font-size: rem(14);
      }
    }

    &_nameInputGroup {
      width: 100%;
      @include flex-jCenter-aStart($dir: column, $gap: rem(2));

      &_nameLabel {
        font-size: rem(15);
      }

      &_nameInput {
        width: 100%;
        background-color: $clr-black;
        color: $clr-white;
        font-size: rem(14);
        padding-inline: em(10);
        padding-block: em(5);
        font-weight: $fw-light;

        &:hover,
        &:focus,
        &:focus-within {
          outline: none;
        }
      }
    }

    &_descriptionInputGroup {
      @extend .upload_rightContainer_nameInputGroup;

      &_descriptionLabel {
        @extend .upload_rightContainer_nameInputGroup_nameLabel;
      }

      &_descriptionInput {
        @extend .upload_rightContainer_nameInputGroup_nameInput;
      }
    }

    &_categoryInputGroup {
      @extend .upload_rightContainer_nameInputGroup;

      &_categoryLabel {
        @extend .upload_rightContainer_nameInputGroup_nameLabel;
      }

      &_categoryInput {
        @extend .upload_rightContainer_nameInputGroup_nameInput;
      }
    }
  }
}

@keyframes uploading {
  from {
    left: -10%;
  }
  50% {
    width: 30%;
  }
  to {
    width: 10%;
    left: 110%;
  }
}

@keyframes oscillate {
  from {
    transform: translateY(7.5%);
  }
  to {
    transform: translateY(-7.5%);
  }
}

@keyframes zoom-in-out {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
