@use 'functions' as fn;

/* ===== Colors ===== */
$clr-black: hsl(0, 0%, 0%);
$clr-black-65: hsl(0, 0%, 0%, 0.65);
$clr-black-50: hsl(0, 0%, 0%, 0.5);
$clr-black-35: hsl(0, 0%, 0%, 0.35);
$clr-white: hsl(0, 0%, 100%);
$clr-white-75: hsl(0, 0%, 100%, 0.75);
$clr-white-50: hsl(0, 0%, 100%, 0.5);
$clr-text-dull: hsl(0, 0%, 64%);
$clr-bg-primary: hsl(0, 0%, 5%);
$clr-disabled: hsl(0, 0%, 67%);

$gradient-uploadpage: linear-gradient(130.6deg, rgba(0, 0, 0, 0.5) 1.58%, rgba(46, 46, 46, 0) 100%);

/* ===== Font Weights ===== */
$fw-thin: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 800;
$fw-black: 900;

/* ===== Breakpoints (min) ===== */
$breakpoints-min: (
  'mini': fn.em(350),
  'xs': fn.em(480),
  'sm': fn.em(620),
  'md': fn.em(768),
  'lg': fn.em(992),
  'xl': fn.em(1120),
  '2xl': fn.em(1280),
  '3xl': fn.em(1440),
  '4xl': fn.em(1600),
  '5xl': fn.em(1920),
  '6xl': fn.em(2250),
  '7xl': fn.em(2500),
);

/* ===== Breakpoints (max) ===== */
$breakpoints-max: (
  'mini': fn.em(349.98),
  'xs': fn.em(479.98),
  'sm': fn.em(619.98),
  'md': fn.em(767.98),
  'lg': fn.em(991.98),
  'xl': fn.em(1119.98),
  '2xl': fn.em(1279.98),
  '3xl': fn.em(1439.98),
  '4xl': fn.em(1599.98),
  '5xl': fn.em(1919.98),
  '6xl': fn.em(2249.98),
  '7xl': fn.em(2499.98),
);
