@use '../../utils' as *;

.games {
  padding-block: em(50);

  &_heading {
    text-align: center;
    font-size: rem(30);
    font-weight: $fw-medium;
    margin-bottom: rem(30);
    width: max-content;
    margin-inline: auto;
    padding-inline: 1px;
    border-bottom: 1px solid white;
    @media screen and (max-width: em(750)) {
      font-size: rem(22);
    }
  }

  &_container {
    width: 90%;
    margin-inline: auto;
    @media screen and (max-width: em(1000)) {
      width: 100%;
      padding-inline: 3rem;
    }
    @media screen and (max-width: em(500)) {
      padding-inline: 1.5rem;
    }
    &_categoryBtns {
      @include flex-jStart-aCenter($gap: rem(24));
      @media screen and (max-width: em(750)) {
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      & li {
        background-color: transparent;
        border: 1px solid rgb(136, 136, 136, 0.4);
        padding-inline: rem(20);
        padding-block: rem(5);
        border-radius: rem(20);
        cursor: pointer;
        transition: background-color 100ms linear;
        white-space: nowrap;
        @media screen and (max-width: em(750)) {
          padding-inline: rem(10);
          padding-block: rem(3);
          font-size: 0.8rem;
        }

        &:hover {
          background-color: rgb(136, 136, 136, 0.3);
          transition: background-color 100ms linear;
        }

        &.active {
          background-color: rgb(136, 136, 136, 0.3);
        }
      }
    }

    &_main {
      margin-top: rem(40);
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: 1fr;
      gap: rem(20);

      @include media('min', 'sm') {
        grid-template-columns: 1fr 1fr;
      }

      @include media('min', 'xl') {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &_game {
        grid-column: span 1;
        border-radius: 10px;
        aspect-ratio: 3/2;
        overflow: hidden;
        position: relative;
        width: min(90%, rem(400));
        margin-inline: auto;
        background-color: $clr-black-50;

        @include media('min', 'sm') {
          width: 100%;
        }

        @media (hover: hover) {
          &_name {
            transform: translateY(100%);
            transition: transform 0.3s ease-in-out;
          }

          &:hover {
            .games_container_main_game_name {
              transform: translateY(0);
              transition: transform 0.3s ease-in-out;
            }
          }
        }

        &_name {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          text-align: center;
          background-color: hsl(0, 0%, 0%, 0.6);
          padding-block: em(15);
          z-index: 2;
        }

        &_thumbnail {
          height: 100%;
          z-index: 1;

          & img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            opacity: 0.7;
            transition: transform 0.3s ease-in-out;

            &:hover {
              transform: scale(1.05);
              transition: transform 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
}
