@use '../../utils' as *;

.loading {
  width: 100%;
  height: calc(100vh - rem(55));
  @include flex-jCenter-aCenter($gap: rem(5));
  &.storeLoading {
    width: fit-content;
    height: fit-content;
    > div {
      width: rem(5);
    }
  }
  &.userAllGames {
    height: calc(100vh - rem(219));
  }
  // &.homeMain {
  //   height: calc(100vh - rem(579));
  // }
  &.exploreLoad {
    height: calc(100vh - rem(255));
  }
  > div {
    aspect-ratio: 1;
    width: rem(15);
    background-color: white;
    border-radius: 50%;
    opacity: 1;
    animation: loading 1s linear infinite reverse;
  }
}

@keyframes loading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.game-as-nft {
  width: 90%;
  margin-inline: auto;
  @media screen and (max-width: em(1000)) {
    width: 100%;
    padding-inline: 3rem;
  }
  @media screen and (max-width: em(500)) {
    padding-inline: 1.5rem;
  }
  @include flex-jCenter-aCenter($gap: 0);
  padding-block: 3rem;

  .game-information-container {
    @include flex-jCenter-aStart($dir: column, $gap: rem(50));
    padding: 1.5rem;
    background: linear-gradient(130.6deg, rgba(0, 0, 0, 0.5) 1.58%, rgba(46, 46, 46, 0) 100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    &:has(.thumbnail-image) {
      max-width: rem(900);
    }
    min-width: calc(rem(900) - 3rem);
    max-width: calc(rem(900) - 3rem);
    @media screen and (max-width: em(1000)) {
      min-width: 100%;
      max-width: 100%;
    }

    .game-container {
      @include flex-jCenter-aCenter();
      position: relative;
      width: 100%;
      aspect-ratio: 9/5;

      .game-iframe-container {
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        position: relative;

        .game-iframe,
        canvas {
          width: 100% !important;
          position: absolute !important;
          inset: 0 !important;
          border: 0;
        }

        .loader {
          width: min(90%, rem(400));
          position: absolute;
          top: 50%;
          height: 10px;
          left: 50%;
          border-radius: 20px;
          background-color: transparent;
          transform: translate(-50%, -50%);
          background-color: $clr-bg-primary;
          overflow: hidden;

          div {
            position: absolute;
            left: 0;
            height: 100%;
            width: 0%;
            background-color: white;
            transition: width 150ms ease-in-out infinite;
          }
        }
      }

      .thumbnail-image {
        display: grid;
        place-content: center;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 1px 18px rgba(255, 255, 255, 0.2));

        img {
          aspect-ratio: 9/5;
          width: rem(850);
          object-fit: cover;
        }
      }

      .play-btn {
        display: grid;
        place-content: center;
        position: absolute;
        font-size: rem(48);
        cursor: pointer;
        @media screen and (max-width: em(600)) {
          font-size: rem(25);
        }
      }
    }

    .information-container {
      @include flex-jCenter-aStart($dir: column, $gap: rem(35));
      width: 100%;

      &:has(.contract-details-form) {
        .game-heading {
          border-bottom: 1px solid rgba(255, 255, 255, 0.35);
          padding-bottom: rem(20);
        }
      }

      .game-heading {
        display: grid;
        grid-template-columns: 7fr 5fr;
        gap: 1rem;
        width: 100%;
        @media screen and (max-width: em(900)) {
          grid-template-columns: 1fr;
        }
        @media screen and (max-width: em(600)) {
          grid-template-columns: 1fr;
        }

        .information {
          @include flex-jStart-aStart($dir: column, $gap: rem(12));
          grid-column: span 1;
          .share-btn {
            margin-top: rem(15);
            color: white;
            padding-inline: rem(15);
            border-radius: rem(35);
            padding-block: em(2.5);
            background-color: $clr-black;
            border: 1px solid rgba(255, 255, 255, 0.6);
            display: flex;
            align-items: center;
            gap: 10px;
            background: transparent;
            width: 100%;
            font-weight: 500;
            font-size: rem(13);
            svg {
              font-size: rem(15) !important;
            }
          }
          .information-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: rem(30);
            .game-name {
              font-size: rem(28);
              white-space: nowrap;
              max-width: 26ch;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: $fw-semibold;
              @media screen and (max-width: em(600)) {
                font-size: rem(20);
              }
              @media screen and (max-width: em(450)) {
                max-width: 20ch;
              }
            }

            @media screen and (max-width: em(450)) {
              flex-direction: column;
              align-items: flex-start;
            }
          }

          .listing-date {
            font-size: rem(13);
            color: #868686;
          }

          .creator-info {
            width: 100%;
            @include flex-jStart-aStart($gap: rem(10));
            flex-direction: column;
            flex-wrap: wrap;
            row-gap: 1.4rem;

            a {
              @media screen and (max-width: em(430)) {
                margin-left: 0;
              }
            }
            p {
              @media screen and (max-width: em(600)) {
                font-size: rem(12);
                white-space: nowrap;
              }
            }
            .creator-info-avatar {
              display: flex;
              gap: 10px;
            }
            .share-btn {
              color: white;
              padding-inline: rem(15);
              border-radius: rem(35);
              padding-block: em(2.5);
              background-color: $clr-black;
              border: 1px solid rgba(255, 255, 255, 0.6);
              display: flex;
              align-items: center;
              gap: 10px;
              background: transparent;
              width: 100%;
              font-weight: 500;
              font-size: rem(13);
              svg {
                font-size: rem(15) !important;
              }
            }

            .creator-avatar {
              position: relative;
              @include flex-jCenter-aCenter($gap: 0);

              .avatar-image {
                position: absolute;
                left: 0;
                display: grid;
                place-content: center;
                aspect-ratio: 1;
                width: rem(40);
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid rgba(255, 255, 255, 0.35);
              }

              .name {
                padding-inline-start: rem(55);
                padding-inline-end: rem(20);
                border-radius: rem(35);
                padding-block: em(2.5);
                background-color: $clr-black;
                border: 1px solid rgba(255, 255, 255, 0.6);
                font-size: rem(14);
              }
            }
          }

          .game-control-btns {
            @include flex-jCenter-aCenter();
            margin-top: rem(16);
            @media screen and (max-width: em(450)) {
              flex-direction: column;
              align-items: normal;
            }

            > button {
              padding: em(13) em(24);
              border-radius: 4px;
              font-size: rem(13);
              font-weight: 500;
              border: 1px solid white;

              &.remove-game-btn {
                background-color: transparent;
                color: white;
                font-weight: 200;
              }
            }
          }
        }

        .action {
          @include flex-jBetween-aStart($dir: column, $gap: rem(10));
          grid-column: span 1;
          position: relative;
          isolation: isolate;
          padding: rem(12) rem(20);
          width: 100%;
          border-radius: 15px;

          &::after,
          &::before {
            content: '';
            position: absolute;
            display: block;
            border-radius: 15px;
          }

          &::before {
            inset: 0;
            z-index: -1;
            background: #030303;
            backdrop-filter: blur(10px);
            border-radius: 15px;
          }

          &::after {
            inset: -1px;
            z-index: -2;
            background: linear-gradient(150.94deg, rgb(255, 255, 255, 0.6) 20%, rgba(255, 255, 255, 0.35) 100%);
            border-radius: 15px;
          }

          .contract-information {
            @include flex-jBetween-aCenter($gap: 0);
            width: 100%;

            .information-group {
              padding-inline-end: rem(12);

              .title {
                font-size: rem(12);
                color: #bbbbbb;
              }

              .info {
                font-size: rem(20);
              }
            }
          }

          .chain-information {
            font-size: rem(16);
            color: #bbbbbb;
            @include flex-jCenter-aStart($dir: column, $gap: rem(0));
            margin-top: rem(10);

            a {
              font-size: rem(12);
              text-decoration: underline;
            }
          }

          .listing-price-details {
            @include flex-jCenter-aStart($gap: rem(4), $dir: column);
            width: 100%;
            margin-block: rem(14);

            & + .action-btn {
              margin-top: rem(15);
            }

            .listed-price {
              font-size: rem(30);
              margin-top: rem(-10);
              font-weight: $fw-medium;
            }

            .price-input-group {
              width: 100%;
              @include flex-jStart-aCenter($gap: rem(10));
              @media screen and (max-width: em(450)) {
                flex-direction: column;
                align-items: flex-start;
                input {
                  width: 100%;
                }
              }

              .label {
                font-size: rem(14);
              }

              input {
                flex: 1;
                border: 1px solid rgba(255, 255, 255, 0.35);
                padding: rem(3) rem(6);
                color: white;
                font-size: rem(20);

                &:focus {
                  outline: none;
                  border-color: rgba(255, 255, 255, 0.7);
                }
              }
            }
          }

          .heading {
            font-size: rem(15);
          }

          .action-btn {
            width: 100%;
            background-color: $clr-white;
            color: $clr-black;
            padding: em(2.5);
            border-radius: rem(20);
            font-weight: $fw-medium;
            margin-top: rem(30);
          }
        }
      }

      .dynamic-action-container {
        padding: rem(25);
        background-color: #0d0d0d;
        width: 100%;
        margin-top: rem(-16);
      }

      .game-upload-container {
        display: grid;
        place-items: center;
        aspect-ratio: 3/1;
        padding: 0;
        margin-top: rem(-8);

        .upload-dropzone {
          width: 100%;
          height: 100%;
          @include flex-jCenter-aCenter($dir: column, $gap: rem(10));
        }

        .game-uploading {
          width: 100%;
          display: grid;
          place-items: center;
          gap: rem(8);

          .launch-game-btn {
            padding: em(5) em(80);
            margin-top: rem(16);
            background: linear-gradient(150.94deg, #000000 3.32%, rgba(0, 0, 0, 0) 98.95%);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            backdrop-filter: blur(8px);
            border-radius: 4px;
            color: white;
            position: relative;
            isolation: isolate;

            &::after,
            &::before {
              content: '';
              position: absolute;
              display: block;
              border-radius: 4px;
            }

            &::before {
              inset: 0;
              z-index: -1;
              background: #030303;
              backdrop-filter: blur(10px);
            }

            &::after {
              inset: -1px;
              z-index: -2;
              background: linear-gradient(150.94deg, #ffffff 10%, rgba(255, 255, 255, 0.25) 40%);
            }
          }

          .upload_leftContainer_dropzone_uploadingMsg {
            max-width: 75ch;
            font-size: rem(12);

            &:first-of-type {
              font-size: rem(16);
            }
          }

          .upload_leftContainer_dropzone_uploader {
            font-size: rem(13);
            margin-top: rem(20);
            width: min(75ch, 95%);
          }
        }
      }

      .contract-details-form {
        @include flex-jBetween-aStart($dir: column, $gap: rem(30));

        .details-heading {
          font-weight: $fw-regular;
          font-size: rem(21);
        }

        .form {
          @include flex-jCenter-aCenter($dir: column);
          width: 100%;

          .form-controll-group-container {
            width: 85%;
            background: linear-gradient(130.6deg, rgba(0, 0, 0, 0.5) 1.58%, rgba(6, 6, 6, 0) 100%);
            backdrop-filter: blur(10px);
            position: relative;
            isolation: isolate;
            padding: rem(40);
            border-radius: 8px;
            @include flex-jCenter-aCenter($dir: column);

            &::after,
            &::before {
              content: '';
              position: absolute;
              display: block;
              border-radius: 8px;
            }

            &::before {
              inset: 0;
              z-index: -1;
              background: #030303;
              backdrop-filter: blur(10px);
              border-radius: 8px;
            }

            &::after {
              inset: -1px;
              z-index: -2;
              background: linear-gradient(
                150.94deg,
                rgba(255, 255, 255, 0.2) 20%,
                rgb(255, 255, 255, 0.4) 100%,
                rgba(255, 255, 255, 0.2) 80%
              );
              border-radius: 8px;
            }

            .form-controll-group {
              @include flex-jCenter-aStart($dir: column, $gap: rem(6));
              width: 100%;

              .label {
                font-size: rem(15);
              }

              .textarea {
                width: 100%;
                background-color: #040404;
                border: 1px solid rgba(255, 255, 255, 0.2);
                height: calc(5 * 1.4 * rem(15));
                color: $clr-white;
                @include flex-jStart-aStart($dir: column, $gap: rem(6));
                padding: rem(4) rem(8);
                font-size: rem(15);

                &:focus {
                  outline: none;
                }
              }
            }
          }

          .submit-btn {
            background-color: white;
            color: black;
            font-weight: $fw-semibold;
            font-size: rem(18);
            padding-block: rem(6);
            padding-inline: rem(40);
            margin-top: rem(20);
            border-radius: 8px;
          }
        }
      }

      .game-description {
        @include flex-jCenter-aStart($dir: column);
        width: 100%;

        .heading {
          width: 100%;
          font-size: rem(20);
          font-weight: $fw-regular;
          padding-block: rem(15);
          border-bottom: 1px solid rgb(255, 255, 255, 0.7);
          @media screen and (max-width: em(600)) {
            font-size: rem(15);
          }
        }

        .description-text {
          height: calc(1.4 * 8 * rem(16));
          @media screen and (max-width: em(600)) {
            font-size: rem(12);
          }
        }
      }
    }
  }
}

.full-screen-btn {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: grid;
  place-content: center;
  width: max-content;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(25px);
  border-radius: 5px;
  padding: rem(2);
  z-index: 9999;

  .full-screen-icon {
    display: grid;
    place-content: center;
    font-size: rem(32.5);
    color: white;
  }
}
