@use '../../utils' as *;

.user {
  padding-block: em(50);
  &_heading {
    text-align: center;
    font-size: rem(30);
    font-weight: $fw-medium;
    margin-bottom: rem(30);
    width: max-content;
    margin-inline: auto;
    padding-inline: 1px;
    border-bottom: 1px solid white;
    @media screen and (max-width: em(750)) {
      font-size: rem(22);
    }
  }
  &_container {
    width: 90%;
    margin-inline: auto;
    .no-game {
      border-radius: rem(15);
      display: grid;
      grid-column: 1 / -1;
      place-content: center;
      background-color: hsla(0, 0%, 0%, 0.5);
      height: calc(100vh - rem(219));
      gap: rem(20);
      .no-game-heading {
        text-align: center;
        @media screen and (max-width: em(330)) {
          font-size: rem(13);
        }
      }
      .no-game-btns {
        display: flex;
        gap: rem(10);
        @media screen and (max-width: em(500)) {
          flex-direction: column;
          align-items: center;
        }
        button {
          background-color: #ababab;
          color: #000;
          padding-block: 0.3125em;
          padding-inline: 0.9375em;
          border-radius: 4px;
          font-weight: 500;
        }
      }
    }
    @media screen and (max-width: em(1000)) {
      width: 100%;
      padding-inline: 3rem;
    }
    @media screen and (max-width: em(500)) {
      padding-inline: 1.5rem;
    }
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: rem(20);

    @include media('min', 'sm') {
      grid-template-columns: 1fr 1fr;
    }

    @include media('min', 'xl') {
      grid-template-columns: 1fr 1fr 1fr;
    }
    // &_dino {
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    // }
    &_userGames {
      grid-column: span 1;
      border-radius: 10px;
      aspect-ratio: 3/2;
      overflow: hidden;
      position: relative;
      width: min(90%, rem(400));
      margin-inline: auto;
      background-color: $clr-black-50;

      @include media('min', 'sm') {
        width: 100%;
      }

      @media (hover: hover) {
        &_name {
          transform: translateY(100%);
          transition: transform 0.3s ease-in-out;
        }
        &:hover {
          .user_container_userGames_name {
            transform: translateY(0);
            transition: transform 0.3s ease-in-out;
          }
        }
      }

      &_name {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: center;
        background-color: hsl(0, 0%, 0%, 0.6);
        padding-block: em(15);
        z-index: 2;
      }
      &_thumbnail {
        height: 100%;
        z-index: 1;
        & img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          opacity: 0.7;
          transition: transform 0.3s ease-in-out;
          &:hover {
            transform: scale(1.05);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
