@use './utils' as *;
@forward './components';

.appbtn {
  padding-block: em(5);
  padding-inline: em(15);
  border-radius: 4px;
  font-weight: $fw-medium;
}

.appbtn:disabled {
  background-color: $clr-disabled;
  color: $clr-black;
}

.gradient-border {
  border: 1px solid;
  border-image-source: linear-gradient(45deg, #888888 0%, #121212 0%, #121212 60%, rgb(136, 136, 136, 0.25) 100%);
  border-image-slice: 1;
}
